import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'
import React, { useEffect, useRef, useState, Fragment } from 'react'
import { useShopStoreIncontext } from '../../../context/shopContext'
import DownArrow from '../../../images/arrow_down.svg'
import UpArrow from '../../../images/arrow_up.svg'
import { trackSignUp, trackStartPers } from '../../../lib/ga4'
import klavyioSignUp from '../../../lib/klavyio'
import navigate from "../../Link/navigate"
import Word from '../../Personalisation/font'
import { navigate as nativeNavigate } from 'gatsby'
import { trackAddToCart } from '../../../lib/ga4'
import SpecsSection from './specs-section'
import bag from '../../../images/icons/bag-white.svg'
import bagBlack from '../../../images/icons/bag.svg'
import Link from '../../Link'
import DropdownSelect from '../../DropdownSelect/DropdownSelect'
import OptionSelect from '../../OptionSelect/OptionSelect'
import { CartAddToCartModules } from '../../Cartv2/UpsellModule'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import { useStaticQuery } from 'gatsby'
import { AccordionBlock } from '../../panels/USPFeatureDetails/usp_feature_details'

const AfterpayLogo = styled(GatsbyImage)`
  width: 60px;
  margin: 0 5px;
`
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;
const PopupContainer = styled.div`
  width: 50%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative; /* To position the close button */
`;
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: black;
`;
const CrossIcon = styled.span`
  font-size: 32px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;
const TableHead = styled.thead`
  border-bottom: 1px solid #ddd;
`;
const TableRow = styled.tr`
  background-color: ${({ index }) => (index % 2 === 0 ? '#f2f2f2' : 'transparent')};
`;
const TableHeader = styled.th`
  padding: 20px;
  text-align: left;
  text-align: center;
`;
const TableCell = styled.td`
  padding: 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
`;
const ProductNameDiv = styled.h1`
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -0.82px;
  color: #000;
`
const SubHeadingText = styled.h1`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  color: #777;
`
const AddedSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.66px;
  text-align: center;
  margin-left: 8px;
`
const UnderlineButton = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1025px) {
    font-size: 0.8em;
  }
`
const SelectorContainer = styled.div`
  margin: 16px;
  width: 450px;
  @media (min-width: 764px) {
    width: 45vw;
    min-width: 45vw;
    // padding: 0 96px;
  }
  @media (max-width: 763px) {
    width: calc(100% - 32px);
  }
`
const Circle = styled.div`
  position: relative;
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  ${({arrayId}) => arrayId === 0 ? 'margin: 0; margin-right: 4px;' : 'margin: 0 4px;'}
  ${(props) =>
    props.selected
      ? `
          &:before {
            content: " ";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border-radius: 50%;
            border: 1px solid #8f8f8f;
            background: transparent;
          }
        `
      : ``}
    background-color: ${(props) => props.color};
  /* overflow: hidden; */
  cursor: pointer;

  ${(props) =>
    props.color === '#101010'
      ? `
      background-color: white;
      border: 2px solid black;
      &:before {
        content: " ";
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: 50%;
        border-radius: 50%;
        background: transparent;
      }
    `
      : ''}

    ${(props) =>
    props.color === '#777983'
      ? `
        background-color: #777983;
        border: 2px solid #C0C0C0;
        &:before {
          content: " ";
          position: absolute;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          border-radius: 50%;
          border-radius: 50%;
          background: transparent;
        }
      `
      : ''}

      ${(props) =>
    props.color === '#f1eade'
      ? `
          background-color: #f1eade;
          border: 2px solid #C0C0C0;
          &:before {
            content: " ";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border-radius: 50%;
            border-radius: 50%;
            background: transparent;
          }
        `
      : ''}

      ${(props) =>
    props.transparent
      ? `
          &:before {
            content: " ";
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            border-radius: 50%;
            border: 1px solid #cb7a4f;
            background: transparent;
          }
        `
      : ''}

  ${({ halfBlack, darkMode }) =>
    halfBlack === true || darkMode === true
      ? `
          &:after {
            position: absolute;
            content: " ";
            background: black none repeat scroll 0% 0%;
            top: 0px;
            bottom: 0px;
            width: 50%;
            border-top-left-radius: 64px;
            border-bottom-left-radius: 64px;
          }
      `
      : ''}
      ${({ halfPink }) =>
    halfPink === true
      ? `
              &:after {
                position: absolute;
                content: " ";
                background: #F1EAE6 none repeat scroll 0% 0%;
                top: 0px;
                bottom: 0px;
                width: 50%;
                border-top-left-radius: 64px;
                border-bottom-left-radius: 64px;
              }
          `
      : ''}
      ${({ halfEcru }) =>
    halfEcru === true
      ? `
                &:after {
                  position: absolute;
                  content: " ";
                  background: #EBE5D3 none repeat scroll 0% 0%;
                  top: 0px;
                  bottom: 0px;
                  width: 50%;
                  border-top-left-radius: 64px;
                  border-bottom-left-radius: 64px;
                }
            `
      : ''}
      ${({ halfRed }) =>
    halfRed === true
      ? `
                &:after {
                  position: absolute;
                  content: " ";
                  background: #DDAEB2 none repeat scroll 0% 0%;
                  top: 0px;
                  bottom: 0px;
                  width: 50%;
                  border-top-left-radius: 64px;
                  border-bottom-left-radius: 64px;
                }
            `
      : ''}
        ${({ halfTan }) =>
    halfTan === true
      ? `
              &:after {
                position: absolute;
                content: " ";
                background: #F0E5DD none repeat scroll 0% 0%;
                top: 0px;
                bottom: 0px;
                width: 50%;
                border-top-left-radius: 64px;
                border-bottom-left-radius: 64px;
              }
          `
      : ''}
      ${({ halfGrey }) =>
    halfGrey === true
      ? `
              &:after {
                position: absolute;
                content: " ";
                background: #E0E0E0 none repeat scroll 0% 0%;
                top: 0px;
                bottom: 0px;
                width: 50%;
                border-top-left-radius: 64px;
                border-bottom-left-radius: 64px;
              }
          `
      : ''}

`
const Strikethrough = styled.div`
  position: absolute;
  content: '';
  ${(props) =>
    props.transparent ?
      `
      left: -10px;
      top: calc(50% - 2px);
      right: -10px;
      border-top: 4px solid;
      border-color: white;
    `
      :
      `
      left: -4px;
      top: calc(50% - 2px);
      right: -4px;
      border-top: 4px solid;
      border-color: white;
    `
  }
  transform: rotate(-45deg);
  z-index: 2;
`
const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  padding-bottom: 10px;
`;
const BreadcrumbItem = styled(Link)`
  margin-right: 5px;
  cursor: pointer;

  &:after {
    content: '>';
    margin-left: 5px;
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
    cursor: pointer;

    &:after {
      content: '';
      margin-left: 0;
    }
  }
`;
const AddToCartPrice = styled.span`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 0 !important;
  justify-content: space-between;
`
const StarContainer = styled.div`
  & .oke-stars-foreground {
    --oke-stars-foregroundColor: #000 !important;
    ${({starColor}) => starColor && `--oke-stars-foregroundColor: ${starColor}!important;`}
  }

  & .oke-sr-count {
    font-size: 14px;
    font-weight: 500;
  }

  & .okeReviews.oke-sr {
    opacity: 0;
    animation: fadeInAnimation 0.4s forwards;
    animation-delay: 0.05s;
  }
`
const StickyContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 99999999 !important;
  transition: bottom 0.2s ease-in-out;
  ${({ isInView, height }) => isInView ? `
    bottom: -${height}px;
    @media (max-width: 1025px) {
      bottom: -${height}px;
    }
  ` : `
    box-shadow: 0px 1px 32px 0px rgba(0,0,0,0.32);
  `}

  @media (max-width: 1025px) {
    flex-direction: column;
  }
`
const ProductInfoSection = styled.div`
  padding: 4px 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #F7F6F4;

  flex-grow: 1;
  color: #000;
  font-size: 16px;
  @media (max-width: 768px) {
    padding: 4px 16px;
  }
`
const ProductTitle = styled.span`
  font-weight: 500;
  padding-right: 1em;
  font-size: 24px;
  lineHeight: 3;

  @media (max-width: 768px) {
    display: none;
  }
`
const ProductColour = styled.span`
  text-transform: uppercase;
  padding-left: 0.4em;
  font-size: 12px;
  @media (max-width: 768px) {

  }
`
const ProductColourText = styled.span`
   text-transform: uppercase;
   padding-left: 0.4em;
   font-size: 14px;
   fontWeight: 500;
   @media (max-width: 768px) {
    font-size: 12px;
   }
`
const StickyAddToCartButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #252525;
  ${({background}) => background && `background: ${background};`}
  color: white;
  width: 350px;
  padding: 4px;
  cursor: pointer;

  ${({ isOOS }) => isOOS && `
    color: #ffffffee;
    background-color: rgba(var(--editions-grey), 0.8);
  `}

  @media (max-width: 1025px) {
    width: 100%;
  }
`
const StickyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  @media (max-width: 1025px) {
    display: none;
  }
`
const SelectedSpan = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.21px;
  color: #282828;
  font-style: bold;
  margin-top: 12px;
  @media (max-width: 763px) {
    margin-top: 8px;
  }
`
const AvaliablityLabel = styled.div`
  font-size: 14px;
  color: black;
  font-weight: 400;
  color: #000;
`

const AddToCartPersContainer = styled.div`
  display: flex;
  @media (max-width: 763px) {
    flex-direction: column;
  }
`
const AddToCartContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 0;
  ${({showPersonalisation}) => showPersonalisation ? 'width: 75%;flex-grow: 1;': 'width: 100%;'}
  @media (max-width: 763px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }
`
const OutOfStockButton = styled.button`
  min-width: calc(50% - 8px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0.66px;
  text-align: center;
  color: #ffffffee;
  // border-radius: 41.5px;
  border: solid 1px rgba(var(--editions-grey), 0.8);
  background-color: rgba(var(--editions-grey), 0.8);
  flex-grow: 1;
  height: 60px;
  margin: 4px 0;
  outline: none;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  transition: all 0.2s;
  border: solid 1px transparent;
  &:hover {
    background-color: rgba(var(--editions-grey), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--editions-grey));
    color: rgb(var(--editions-grey));
  }
`
const SignupSection = styled.div`
  background-color: #F7F6F4;
  display: flex;
  align-items: stretch;
  // border-radius: 8px;
  @media (max-width: 1025px) {
    flex-direction: column-reverse;
  }
`
const SignupContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: center;
  }
`
const AddToCartIcon = styled.img`
  @media (max-width: 890px) {
    display: none;
  }
`
const AddToCartButton = styled.button`
  min-width: calc(50% - 8px);
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
  color: #ffffffee;
  // border-radius: 41.5px;
  border: solid 1px #000;
  background-color: #000;
  font-weight: 400;
  flex-grow: 1;
  height: 60px;
  margin: 4px 0;
  outline: none;
  user-select: none;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
  transition: all 0.2s;
  border: solid 1px transparent;
  ${({background}) => background && `
    background: ${background};
      &:hover {
        background-color: ${background}ee;
        color: #ffffff;
        border: solid 1px transparent;
      }
  `}
  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--black));
    color: rgb(var(--black));
  }

  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
`
const PersButton = styled.button`
  min-width: calc(25% - 8px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
  color: #000;
  padding: 0 8px;
  border: solid 1px #000;
  background-color: #fff;
  font-weight: 400;
  flex-grow: 1;
  height: 60px;
  margin: 4px 0;
  outline: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  transition: all 0.2s;
  &:hover {
    background-color: rgba(var(--black), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--black));
    color: rgb(var(--black));
  }

  // ${({background}) => background && `
  //   border: solid 2px ${background};
  //   color: #000;
  //   margin-right: 2px;
  //   &:hover {
  //     background-color: ${background}ee;
  //     color: #ffffff;
  //     border: solid 1px transparent;
  //   }
  // `}


  @media (max-width: 1090px) {
    font-size: 14px;
    min-width: calc(100% - 8px);
    padding: 0 24px;
  }
`
const SignupSubHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.91;
  letter-spacing: 0.23px;
  margin-bottom: 25px;
  color: rgb(var(--black));
`
const SignupHeading = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.2px;
  color: rgb(var(--black));
`
const EmailButtonContainer = styled.div`
  display: flex;
  margin: 8px 0;
  // border-radius: 4px;
  overflow: hidden;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
`
const TransparentButtonSign = styled.button`
  // border-radius: 27px;
  border: solid 1px rgb(var(--black));
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: rgb(var(--black));
  background: transparent;
  height: 48px;
  padding: 0 16px;
  outline: none;
  @media (max-width: 1025px) {
    background: rgb(var(--black));
    color: white;
  }
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(var(--black), 0.2);
    color: white;
    border-color: transparent;
  }
  &:hover {
    background-color: rgba(var(--black), 1);
    color: white;
    border-color: transparent;
  }
`
const TextInputContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.97);
  padding: 4px 12px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  margin-right: 16px;
  flex-grow: 1;
  @media (max-width: 1025px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`
const TextInput = styled.input`
  width: 100%;
  font-size: 18px;
  font-family: Objectivity;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.52px;
  color: #949494;
  border: none;
  outline: none;
  @media (max-width: 1025px) {
    text-align: center;
  }
`
const ReverseAddToCartOnMobile = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1025px) {
    flex-direction: column-reverse;
  }
`

const AccordianBody = styled.div`
  & a {
    text-decoration: underline;
  }

  & p {
    margin: 16px 0;
    margin-top: 0;
  }

  & ul {
    list-style-type: initial;
    margin: 0;
    padding: 0 0 0 16px;
  }

  @media (max-width: 763px) {
    margin-bottom: 0;
  }

  & table {
    width: 100%;
  }

`

const DropdownContainer = styled.div`
  @media (min-width: 763px) {
    max-width: 400px;
  }
`

const NameStarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 763px) {
    flex-direction: column;
  }

`

const OkendoStarRating = ({ reviewData, starColor }) => {
  return (
    <a href="#product-review">
      <svg id="oke-star-symbols" style={{ display: 'none' }} data-oke-id="oke-star-symbols">
        <symbol id="oke-star-empty" style={{ overflow: 'visible' }}><path id="star-default--empty" fill="var(--oke-stars-backgroundColor)" stroke="var(--oke-stars-borderColor)" strokeWidth="var(--oke-stars-borderWidth)" d="M3.34 13.86c-.48.3-.76.1-.63-.44l1.08-4.56L.26 5.82c-.42-.36-.32-.7.24-.74l4.63-.37L6.92.39c.2-.52.55-.52.76 0l1.8 4.32 4.62.37c.56.05.67.37.24.74l-3.53 3.04 1.08 4.56c.13.54-.14.74-.63.44L7.3 11.43l-3.96 2.43z" /></symbol>
        <symbol id="oke-star-filled" style={{ overflow: 'visible' }}><path id="star-default--filled" fill="var(--oke-stars-foregroundColor)" stroke="var(--oke-stars-borderColor)" strokeWidth="var(--oke-stars-borderWidth)" d="M3.34 13.86c-.48.3-.76.1-.63-.44l1.08-4.56L.26 5.82c-.42-.36-.32-.7.24-.74l4.63-.37L6.92.39c.2-.52.55-.52.76 0l1.8 4.32 4.62.37c.56.05.67.37.24.74l-3.53 3.04 1.08 4.56c.13.54-.14.74-.63.44L7.3 11.43l-3.96 2.43z" /></symbol>
      </svg>
      <StarContainer
        data-oke-star-rating
        data-oke-reviews-product-id
        data-oke-rendered=""
        starColor={starColor}
      >
        <div className="okeReviews oke-sr">
          <div className="">
            <div className="oke-sr-rating"> {formatDecimal(reviewData.aggregateRating)} </div>
            <div className="oke-sr-stars">
              <div className="oke-stars">
                <div className="oke-stars-background"><svg height="12" viewBox="0 0 79.22222222222221 14" aria-hidden="true">
                  <use x="0" href="#oke-star-empty"></use>
                  <use x="16.155555555555555" href="#oke-star-empty"></use>
                  <use x="32.31111111111111" href="#oke-star-empty"></use>
                  <use x="48.46666666666667" href="#oke-star-empty"></use>
                  <use x="64.62222222222222" href="#oke-star-empty"></use>
                </svg></div>
                <div className="oke-stars-foreground" style={{ width: `calc(100% * ${reviewData.aggregateRating / 5})` }}><svg height="12" viewBox="0 0 79.22222222222221 14"
                  aria-hidden="true">
                  <use x="0" href="#oke-star-filled"></use>
                  <use x="16.155555555555555" href="#oke-star-filled"></use>
                  <use x="32.31111111111111" href="#oke-star-filled"></use>
                  <use x="48.46666666666667" href="#oke-star-filled"></use>
                  <use x="64.62222222222222" href="#oke-star-filled"></use>
                </svg></div><span className="oke-a11yText">Rated {formatDecimal(reviewData.aggregateRating)} stars</span>
              </div>
            </div>
            <div className="oke-sr-count"><span className="oke-sr-count-number">{nFormat.format(reviewData.reviewCount)}</span><span className="oke-sr-label-text">{' '} Reviews</span></div>
          </div>
        </div>
      </StarContainer>
    </a>
  )
}
export const ColorSelector = ({
  selectedColor,
  setSelectedColor,
  colors,
  addSix,
  small,
  plusOne,
}) => {
  return colors.map((color, keyA) => {
    let key = addSix ? keyA + 6 : keyA
    if (plusOne) {
      key = keyA + 1
    }
    return (
      <span key={key}>
        <Circle
          size={small ? 24 : 24}
          key={key}
          style={{
            marginLeft: key === 0 ? 0 : key === 6 ? 0 : 3,
          }}
          onClick={() => {
            return setSelectedColor(key)
          }}
          color={color?.replace('545454', '0c0c0c').replace('4B4E5C', '324058')}
          black={color === '#E6E6E6'}
          selected={key === selectedColor}
        />
      </span>
    )
  })
}
export const SmartColorSelector = ({
  selectedColor,
  setSelectedColor,
  colors,
}) => {
  return (
    <>
      {colors
        .filter((colorObject) => colorObject['oos']?.on_site !== false)
        .map((colorObject, arrayId) => {
          const color = colorObject['color']
          const key = colorObject['key']
          const oos = colorObject['oos']?.avaliable === false
          const containsMessage =
            colorObject['oos']?.oos_message !== undefined && colorObject['oos']?.oos_message !== ''
          return (
            <Fragment key={`color-${color}`}>
              <Circle
                id={arrayId}
                arrayId={arrayId}
                className={`selectedC-color-${selectedColor}`}
                size={22}
                key={`selected-color-${arrayId}`}
                onClick={() => setSelectedColor(key)}
                color={color?.replace('545454', '0c0c0c').replace('4B4E5C', '324058').toLowerCase()}
                black={color === '#E6E6E6'}
                selected={key === selectedColor}
                orange={containsMessage}
                halfBlack={color === '#3E4289' || color === '#2c572c'}
                halfPink={color === '#614049'}
                halfEcru={color === '#754935'}
                halfRed={color === '#B9253B'}
                halfTan={color === '#B37B5B'}
                halfGrey={color === '#080808'}
                darkMode={['#828282', '#FF0000', '#143BFF', '#ffd900'].includes(color)}
                transparent={oos && (color === '#FDFDFD' || color === '#fdfdfd')}
              >
                {oos && <Strikethrough transparent={(color === '#FDFDFD' || color === '#fdfdfd' || color === '#101010')} color={color} />}
              </Circle>
            </Fragment>
          )
        })}
    </>
  )
}
const Personalise = ({ labelData }) => {
  const displaySize = {
    height: 25,
    width: 100,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  }
  if (!labelData) return <></>
  return (
    <Word
      left={true}
      maxLetters={20}
      size={displaySize}
      mainCol={labelData.color}
      backCol={labelData.shadow}
      letters={labelData.text}
      fontSize={32}
      font={labelData.font}
    />
  )
}
const NewPersonaliseButton = ({
  setPersonaliseOpen,
  labelData,
  personalised,
  isLuggage,
  freePersonalisation,
  freeSuitcasePersonalisation,
  personalisationPrice,
  persDisabled,
  trackingItem,
  background
}) => {
  let price = `${personalisationPrice}`
  const { storeLocale, currencyCode, currencyPrefix } = useShopStoreIncontext(
    (state) => {
      return {
        storeLocale: state.storeLocale,
        currencyCode: state.currencyCode,
        currencyPrefix: state.currencyPrefix,
      }
  })
  const text = <span> {storeLocale === 'en' ? 'Personalize' : 'Personalise'} </span>

  let currencySymbol = currencyPrefix?.match(/[^a-z0-9]/gi);  // Find all symbols

  let priceSPan = (
    <span>
      <span style={{ fontWeight: 500, paddingLeft: 4 }}> {'+'}{currencySymbol}{price} </span>{' '}
    </span>
  )

  if (freePersonalisation || (freeSuitcasePersonalisation && isLuggage)) {
    priceSPan = (
      <span style={{fontSize: '0.8em'}}>
        <span style={{ textDecoration: 'line-through' }}>{'+'}{currencySymbol}{price}</span>{' '}
        <span style={{ fontWeight: 500, paddingLeft: 4 }}> FREE </span>{' '}
      </span>
    )
  }

  const [priceSpan, setPriceSpan] = useState(<> </>)
  useEffect(() => {
    setPriceSpan(
      <>
        <span style={{ textTransform: 'uppercase', marginRight: 12 }}>
          {text}
        </span>
        <span>
          {priceSPan}{' '}
        </span>
      </>
    )
  }, [freePersonalisation])

  if (persDisabled === true) {
    return (
      <div style={{ position: 'relative', display: 'flex' }}>
        <Link styles={{ width: '100%' }} to="/community">
          <div className="personaliseButton-2">
            <UnderlineButton className="hover hover-2">
              <span>
                Personalisation for luggage is temporarily paused due to high demand. <br /> Click{' '}
                <u>here</u> to be notified when it returns.
              </span>
            </UnderlineButton>
          </div>
        </Link>
      </div>
    )
  }

  const handleStartPers = () => {
    setPersonaliseOpen(true)
    trackStartPers({ items: trackingItem, value: personalisationPrice, pet: false, currency: currencyCode })
  }

  return (
    <div onClick={handleStartPers} style={{ position: 'relative', display: 'flex' }}>
      <PersButton background={background}>
        {personalised ? (
          priceSpan
        ) : (
          <>
            <Personalise isLuggage={isLuggage} labelData={labelData} />
            <AddedSpan> ADDED </AddedSpan>
          </>
        )}
      </PersButton>
    </div>
  )
}

const StickyAddToCart = ({
  isInView,
  name,
  colorName,
  oosData,
  trackingItem,
  showPersonalisation,
  handleAddToCart,
  discount,
  currencyPrefix,
  price,
  hideBundlePricing,
  personalisationPrice,
  freePersonalisation,
  freeSuitcasePersonalisation,
  setPersonaliseOpen,
  labelData,
  personalised,
  isLuggage,
  discountPromoOn,
  background
}) => {
  const stickyRef = useRef()
  const [height, setHeight] = useState(200);

  useEffect(() => {
    setHeight(stickyRef?.current?.offsetHeight);
  }, [isInView]);

  const { storeLocale, currencyCode } = useShopStoreIncontext((state) => {
    return { storeLocale: state.storeLocale, currencyCode: state.currencyCode }
  })
  const text = <span> {storeLocale === 'en' ? 'Personalize' : 'Personalise'} </span>

  let currencySymbol = currencyPrefix?.match(/[^a-z0-9]/gi);  // Find all symbols

  let priceSPan = (
    <span>
      <span style={{ fontWeight: 500, paddingLeft: 4, textTransform: 'uppercase' }}> {'+'}{currencySymbol}{personalisationPrice}{' '} </span>{' '}
    </span>
  )
  if (freePersonalisation || (freeSuitcasePersonalisation && isLuggage)) {
    priceSPan = (
      <span style={{ fontSize: 13 }}>
        <span style={{ textDecoration: 'line-through', textTransform: 'uppercase' }}>{storeLocale === 'en-GB' ? '+£' : '+$'}{personalisationPrice}</span>{' '}
        <span style={{ fontWeight: 500, paddingLeft: 4 }}> FREE </span>{' '}
      </span>
    )
  }

  const [priceSpan, setPriceSpan] = useState(<> </>)
  useEffect(() => {
    setPriceSpan(
      <>
        <span style={{ textTransform: 'uppercase' }}>
          {text}
        </span>
        <span>
          {priceSPan}{' '}
        </span>
      </>
    )
    setTimeout(() => {
      setHeight(stickyRef?.current?.offsetHeight);
    }, 200)
  }, [freePersonalisation])

  const isOOS = oosData?.on_site === false || oosData?.avaliable === false

  return (
    <StickyContainer height={height} ref={stickyRef} isInView={isInView}>
      {!isInView && (
        <Global
          styles={css`
            .gorgias-chat-key-1spa6uy {
              bottom: ${height + 20}px !important;
            }
            #attentive_creative {
              bottom: ${height + 20}px !important;
              right: 16px;
            }
            @media (max-width: 1025px) {
              .gorgias-chat-key-1spa6uy {
                bottom: ${height + 20}px !important;
              }
              #attentive_creative {
                bottom: ${height + 20}px !important;
              }
            }
          `}
        />
      )}
      <Global
        styles={css`
          .gorgias-chat-key-1spa6uy {
            transition: bottom 0.2s ease-in-out;
          }
          #attentive_creative {
            transition: bottom 0.2s ease-in-out, opacity 0.2s ease-in-out !important;
          }
        `}
      />
      <StickyButtonContainer>
        <ProductInfoSection onClick={() => nativeNavigate('#product-top')}>
          <ProductTitle>{name}</ProductTitle>
          {/* Icons provided by https://github.com/atisawd/boxicons, licensed under CC BY 4.0 */}
          <ProductColourText>COLOUR: </ProductColourText>
          <ProductColour>{' '}{colorName}</ProductColour>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
        </ProductInfoSection>
        {
          showPersonalisation && (
            <div onClick={() => setPersonaliseOpen(true)} style={{ position: 'relative', display: 'flex', background: '#F7F6F4' }}>
              <div className="personaliseButton" style={{ marginBottom: 0 }}>
                <PersButton style={{ height: 50 }}>
                  {personalised ? (
                    priceSpan
                  ) : (
                    <Personalise isLuggage={isLuggage} labelData={labelData} />
                  )}
                </PersButton>
              </div>
            </div>
          )
        }
      </StickyButtonContainer>
      <StickyAddToCartButton isOOS={isOOS} onClick={() => !isOOS && (handleAddToCart(), trackAddToCart({ value: price, currency: currencyCode, items: trackingItem, sticky: true }))} background={background}>
        {
          isOOS ? (
            <>
              OUT OF STOCK
            </>
          ) : (
            <>
              <AddToCartPrice style={{ padding: 20 }}>
                {(!hideBundlePricing || discountPromoOn) && discount?.amount > 0 ? (
                  <>
                    <span>
                      <span
                        style={{
                          textDecoration: 'line-through',
                          marginRight: 8,
                          color: '#ffffffDD',
                          fontSize: '0.8em',
                        }}
                      >
                        {currencyPrefix}{price}
                      </span>
                    </span>
                    {currencyPrefix}{price - discount.amount}
                  </>
                ) : (
                  <>
                    {currencyPrefix}{price}
                  </>
                )}
              </AddToCartPrice>
              <span style={{ padding: 20 }}> ADD TO CART </span>
            </>
          )
        }
      </StickyAddToCartButton>
    </StickyContainer >
  )
}

const capitalizeFirstLetter = (url) => {
  return url.replace(/\b\w/g, (match) => match.toUpperCase());
};

const Breadcrumb = ({ url, name, shortName, product_categories }) => {
  const segments = url.split('/').filter((segment) => segment !== 'products' && segment !== '');
  return (
    <BreadcrumbContainer>
      {segments.map((segment, index) => {
        const isLast = index === segments.length - 1;
        return (
          <Fragment key={`breadcrumb-${index}`}>
            {isLast ? (
              <BreadcrumbItem to={`/${segments.slice(0, index + 1).join('/')}`}>
                {(shortName || name) ? capitalizeFirstLetter(shortName ? shortName : name) : capitalizeFirstLetter(segment.replace(/-/g, ' '))}
              </BreadcrumbItem>
            ) : (
              <>
                <BreadcrumbItem to={`/${segments.slice(0, index + 1).join('/')}`}>
                  {capitalizeFirstLetter(segment.replace('travel-accessories', 'accessories').replace(/-/g, ' '))}
                </BreadcrumbItem>
                {product_categories.includes("Trunk Collection Page") && (
                  <BreadcrumbItem to={'/trunk-luggage-collection/'}>Trunk</BreadcrumbItem>
                )}
                {product_categories.includes("Light Luggage") || product_categories.includes("Light Set") && (
                  <BreadcrumbItem to={'/light-luggage-collection/'}>Light</BreadcrumbItem>
                )}
                {product_categories.includes("Everyday Range") && (
                  <BreadcrumbItem to={'/everyday-range/'}>Everyday</BreadcrumbItem>
                )}
                {product_categories.includes("SLG") && (
                  <BreadcrumbItem to={'/small-leather-goods/'}>SLG</BreadcrumbItem>
                )}
                {product_categories.includes("VolumePlus") && (
                  <BreadcrumbItem to={'/volume-plus-collection/'}>Volume+</BreadcrumbItem>
                )}
                {product_categories.includes("Carry All") && (
                  <BreadcrumbItem to={'/carry-all-collection/'}>Carry All</BreadcrumbItem>
                )}
                {product_categories.includes("Daybreak") && (
                  <BreadcrumbItem to={'/daybreak-collection/'}>Daybreak</BreadcrumbItem>
                )}
                {product_categories.includes("Juliette") && (
                  <BreadcrumbItem to={'/juliette-collection/'}>Juliette</BreadcrumbItem>
                )}
                {(product_categories.includes("Luggage Covers") ||
                  product_categories.includes("Luggage Covers - Classic") ||
                  product_categories.includes("Luggage Covers- Transparent Mesh") ||
                  product_categories.includes("Luggage Covers - Trunk")) && (
                    <BreadcrumbItem to={'/travel-accessories/luggage-cover/'}>Luggage Cover</BreadcrumbItem>
                  )}
                {product_categories.includes("Classic Range") && (
                  <BreadcrumbItem to={'/classic-luggage-collection/'}>Classic</BreadcrumbItem>
                )}
              </>
            )}
          </Fragment>
        );
      })}
    </BreadcrumbContainer>
  );
};

function formatDecimal(number) {
  if (Number.isInteger(number)) {
    return number?.toFixed(0); // No decimal places
  } else {
    return number?.toFixed(1); // One decimal place
  }
}

const nFormat = new Intl.NumberFormat();

const chunk = (arr, size) =>
  arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), [])

const SelctorSection = ({
  name,
  inViewRef,
  price,
  selectedColor,
  setSelectedColor,
  setSelectedDropdownItem,
  selectedDropdownItem,
  handleAddToCart,
  reviewId,
  variants,
  expandSections,
  hasPersonlisation,
  personalisationCost,
  freePersonalisation,
  freeSuitcasePersonalisation,
  personalised,
  petPersonalised,
  labelData,
  setPersonaliseOpen,
  selectedVariant,
  noSet,
  discount,
  signup_id,
  useDropdown,
  dropdownData,
  url,
  hideBundlePricing,
  upsell,
  returnAddToCart,
  comparison,
  isInView,
  shortName,
  AirlineReq,
  disablePersonalisation,
  reviewData,
  discountPromoOn,
  disableEmbroidery,
  isBag,
  productId,
  stickySelector,
  subHeading
}) => {
  // useEffect(() => {
  //   window.tolstoyAppKey = 'cf6225d4-4e0f-43bd-8a2b-753f53f7ffcb'
  //   var s = document.createElement('script')
  //   s.type = 'text/javascript'
  //   s.async = true
  //   s.src = 'https://widget.gotolstoy.com/widget/widget.js'
  //   document.head.appendChild(s)
  // }, [])

  const { storeLocale, currencyPrefix, mixpanel } = useShopStoreIncontext((state) => {
    return { storeLocale: state.storeLocale, currencyPrefix: state.currencyPrefix, mixpanel: state.mixpanel }
  })
  const { afterpayLogo } = useStaticQuery(graphql`
    {
      afterpayLogo: file(relativePath: { eq: "afterpaylogo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          gatsbyImageDataMobile: gatsbyImageData(quality: 85, placeholder: NONE, width: 550)
        }
      }
    }
  `)

  let currencySymbol = currencyPrefix?.match(/[^a-z0-9]/gi)
  let currency = currencyPrefix?.match(/[a-z0-9]/gi)

  let allColors = variants.map((variant, key) => ({
    key: key,
    color: variant.Colour,
    oos: variant.oosData,
    limited: variant.limited,
    edition: variant.Edition,
  }))

  const smartMapList = [
    ...chunk(
      allColors
        .filter((val) => val.limited !== true && val.edition === null)
        .filter((val) => val.oos && val.oos.preorder === false && val.oos.on_site === true)
        .sort((x, y) => (x.oos.avaliable === y.oos.avaliable ? 0 : x.oos.avaliable ? -1 : 1)),
      10
    ),
    ...chunk(
      allColors
        .filter((val) => val.limited !== true && val.edition !== 'Matte' && val.edition !== null)
        .filter((val) => val.oos && val.oos.preorder === false && val.oos.on_site === true)
        .sort((x, y) => (x.oos.avaliable === y.oos.avaliable ? 0 : x.oos.avaliable ? -1 : 1)),
      10
    ),
    ...chunk(
      allColors
        .filter((val) => val.limited !== true && val.edition !== 'Gloss' && val.edition !== null)
        .filter((val) => val.oos && val.oos.preorder === false && val.oos.on_site === true)
        .sort((x, y) => (x.oos.avaliable === y.oos.avaliable ? 0 : x.oos.avaliable ? -1 : 1)),
      10
    ),
    ...chunk(
      allColors
        .filter((val) => val.limited === true && val.edition === null)
        .filter((val) => val.oos && val.oos.preorder === false && val.oos.on_site === true)
        .sort((x, y) => (y.key - x.key))
        .sort((x, y) => (x.oos.avaliable === y.oos.avaliable ? 0 : x.oos.avaliable ? -1 : 1)),
      10
    ),
    ...chunk(
      allColors.filter((val) => val.oos && val.oos.preorder === true && val.oos.on_site === true),
      10
    ),
  ]

  const showPersonalisation = hasPersonlisation && selectedVariant.oosData?.personalisation !== false && !disablePersonalisation && (isBag && disableEmbroidery ? false : true)
  const filteredUpsell = upsell?.Product?.Variants.filter((val) => val.oosData.on_site && val.oosData.avaliable)

  let [email, setEmail] = useState('')
  let [sent, setSent] = useState(false)
  let [emailValid, setEmailValid] = useState(true)
  let [phoneValid, setPhoneValid] = useState(true)
  let [isPhone, setIsPhone] = useState(false)

  const placeholderSignUp =
    storeLocale === 'en' ? 'your@email.com or +11234567890' :
      storeLocale === 'en-AU' ? 'your@email.com or +61412345678' :
        storeLocale === 'en-NZ' ? 'your@email.com or +64412345678' :
          'your@email.com or +4412345678';

  const handleChange = (e) => {
    setEmail(e.target.value)
    if (!emailValid) {
      validateEmail(e.target.value)
    }
    if (!phoneValid) {
      validatePhone(e.target.value)
    }
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    if (e.target.value.length > 0 && !regex.test(e.target.value)) {
      setIsPhone(false)
      return false
    } else {
      setIsPhone(true)
    }
    trackSignUp({ method: email })
  }

  const handleEnter = async () => {
    if (validateEmail(email) || validatePhone(email)) {
      await klavyioSignUp({
        ...(isPhone ? {
          phone_number: email
        } : {
          email
        }),
        signupID: signup_id,
        source: 'OOS Signup'
      })

      if (selectedVariant?.SignUpID) {
        await klavyioSignUp({
          ...(isPhone ? {
            phone_number: email
          } : {
            email
          }),
          signupID: selectedVariant?.SignUpID,
          source: 'OOS Signup'
        })
      }
      
      setEmail('')
      setSent(true)
    }
  }

  const add = (event) => {
    if (event.keyCode === 13) {
      handleEnter()
    }
  }

  const validatePhone = (value) => {
    const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    if (value.length > 0 && !regex.test(value)) {
      setPhoneValid(false)
      setIsPhone(false)
      return false
    }
    setPhoneValid(true)
    setIsPhone(true)
    return true
  }

  const validateEmail = (value) => {
    const regex =
      /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // eslint-disable-line
    if (value.length > 0 && !regex.test(value)) {
      setEmailValid(false)
      return false
    }
    setEmailValid(true)
    return true
  }

  const handleColourChange = (key) => {
    if (variants[key].handle === undefined) {
      setSelectedColor(key)
    } else {
      navigate(`${variants[key].handle}?${variants[key].ColourName.toLowerCase().split(' ').join('-').replace('/', 'slash')}`, storeLocale)
    }
  }

  const trackingItem = { item_name: name, item_variant: variants[selectedColor].ColourName, item_id: variants[selectedColor].SKU, price: price, quantity: 1 }

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const Popup = ({ onClose, data, productName }) => {
    const handleOverlayClick = (event) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    };
    return (
      <Overlay onClick={handleOverlayClick}>
        <PopupContainer>
          <CloseButton onClick={onClose}>
            <CrossIcon>&times;</CrossIcon>
          </CloseButton>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>AIRLINE</TableHeader>
                <TableHeader>{productName}</TableHeader>
              </TableRow>
            </TableHead>
            <tbody>
              {data?.SizeCompatibility?.map((row, index) => (
                <TableRow key={index} index={index}>
                  <TableCell>{row.AirlineName}</TableCell>
                  <TableCell>{row.Compatible ? "✔️" : "✘"}</TableCell>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </PopupContainer>
      </Overlay>
    );
  };

  const itemType = url.split('/').filter((segment) => segment !== 'products' && segment)[0];

  useEffect(() => {
    const productTracking = {
      "Item Id": productId,
      "Price": price,
      "Item Name": name,
      "Item Type": itemType,
      "Currency": currencyPrefix,
    };

    mixpanel?.track("Item Detail Page Viewed", productTracking);
  }, []);

  return (
    <SelectorContainer id="selector-top">
      <div
        style={stickySelector ? {position: 'sticky', top: 'var(--pdp-offset)', transition: 'top 0.4s ease', zIndex: 1} : {}}
      >


      <StickyAddToCart
        isInView={isInView}
        name={name}
        colorName={selectedVariant.ColourName}
        oosData={selectedVariant.oosData}
        handleAddToCart={handleAddToCart}
        discount={discount}
        currencyPrefix={currencyPrefix}
        price={price}
        isLuggage={selectedVariant.product_categories?.includes("Luggage")}
        isBag={selectedVariant.product_categories?.includes("Bags", "Soft Good (Excluding Weekenders)", "Weekender")}
        hideBundlePricing={hideBundlePricing}
        disablePersonalisation={disablePersonalisation}
        disableEmbroidery={disableEmbroidery}
        trackingItem={trackingItem}
        showPersonalisation={showPersonalisation}
        personalisationPrice={personalisationCost}
        freePersonalisation={freePersonalisation}
        freeSuitcasePersonalisation={freeSuitcasePersonalisation}
        setPersonaliseOpen={setPersonaliseOpen}
        labelData={labelData}
        personalised={personalised}
        productPrice={price}
        selectedVariant={selectedVariant.product_categories}
        discountPromoOn={discountPromoOn}
        background={variants[selectedColor]?.ColourName === 'Blush Pink' ? variants[selectedColor]?.Colour : undefined}
      />
      {/* <Breadcrumb url={url} name={name} shortName={shortName} product_categories={selectedVariant.product_categories} /> */}
      <NameStarContainer>
        <div>
          <ProductNameDiv id="product-top">{name}</ProductNameDiv>
          {subHeading && (
            <SubHeadingText>{subHeading}</SubHeadingText>
          )}
        </div>
        {reviewData && reviewData.aggregateRating > 0 &&  (
          <div
            style={{
              minHeight: '29px',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <OkendoStarRating reviewData={reviewData} starColor={variants[selectedColor]?.ColourName === 'Blush Pink' ? variants[selectedColor]?.Colour : undefined} />
          </div>
        )}
      </NameStarContainer>


      {dropdownData.length > 0 && (
        <DropdownContainer>
          <DropdownSelect
            selected={selectedDropdownItem}
            optionsList={dropdownData.map((variant, id) => ({
              id: id,
              name: variant.DropdownItem,
              link: variant.to,
              secondary: ' ',
            }))}
            setValue={(e) => setSelectedDropdownItem(e.name)}
          />
        </DropdownContainer>
      )}
      {/* {useToggleSelector && (
        <div style={{ margin: '16px 0', display: 'flex' }}>
          {aboveColorSelector.map((item, key) => (
            <StyledLink to={`${item.to}?${currentSearchParams}`} selected={item.to === url} key={key}>
              <div style={{ padding: 4, borderRadius: 25 }}>
                <div style={{ color: item.to === url ? '#000' : '#7b7b7a', fontWeight: 500 }}>
                  {item.ProductTitle}
                </div>
                <MobileFontSize style={{ borderRadius: 25 }}>
                  {item.FeatureItems.map((feature, index) => (
                    <div style={{ display: 'flex', marginBottom: 5, alignItems: 'center' }} key={index}>
                      <p>{feature.FeatureDescription}</p>
                    </div>
                  ))}
                </MobileFontSize>
              </div>
            </StyledLink>
          ))}
        </div>
      )} */}
      {useDropdown === true ? (
        <>
          {
            variants.length === 1 ? (
              <></>
            ) : (
              <div>
                {name === 'Digital Gift Card' ? (
                  <OptionSelect
                    selected={selectedColor}
                    optionsList={variants.map((variant, id) => ({
                      id: id,
                      name: variant.ColourName,
                      secondary: '',
                    }))}
                    setValue={setSelectedColor}
                  />
                ) : (
                  <DropdownSelect
                    selected={selectedColor}
                    optionsList={variants.map((variant, id) => ({
                      id: id,
                      name: variant.ColourName,
                      secondary: '',
                    }))}
                    setValue={setSelectedColor}
                  />
                )}
              </div>
            )
          }
        </>
      ) : (
        <>
          <SelectedSpan>
            <span style={{ fontWeight: 500, letterSpacing: '-0.66px' }}>{storeLocale === 'en' ? 'COLOR' : 'COLOUR'}:</span>{' '}
            <span style={{ fontWeight: '400', maxWidth: 400 }}>
              {variants[selectedColor].ColourName}{' '}
              {selectedVariant.oosData?.oos_message !== undefined &&
                selectedVariant.oosData?.oos_message !== '' && (
                  <>
                    <span
                      style={{
                        color: 'rgb(var(--terracota))',
                        paddingLeft: 12,
                        textTransform: 'uppercase',
                      }}
                    >
                      {selectedVariant.oosData?.oos_message}
                    </span>
                    <br />
                  </>
                )}
              {discount.percentage > 0 && (
                <span
                  style={{
                    color: '#000000',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    background: '#2e2e2e20',
                    padding: 3,
                    paddingLeft: 3,
                    borderRadius: 5,
                  }}
                >
                  {`${discount.percentage}% OFF Limited Stock`}
                </span>
              )}
            </span>
          </SelectedSpan>
          {smartMapList.map((val, key) => (
            <div key={key}>
              {key === smartMapList.findIndex((val) => val[0]?.oos.preorder === true) &&
                val.length > 0 && (
                  <AvaliablityLabel
                    style={{
                      marginTop: 8,
                    }}
                  >
                    PREORDER
                  </AvaliablityLabel>
                )}
              {key === smartMapList.findIndex((val) => val[0]?.edition === 'Matte' && val[0]?.limited !== true) &&
                val.length > 0 && (
                  <AvaliablityLabel
                    style={{
                      marginTop: 8,
                    }}
                  >
                    MATTE
                  </AvaliablityLabel>
                )}
              {key === smartMapList.findIndex((val) => val[0]?.edition === 'Gloss' && val[0]?.limited !== true) &&
                val.length > 0 && (
                  <AvaliablityLabel
                    style={{
                      marginTop: 8,
                    }}
                  >
                    GLOSS
                  </AvaliablityLabel>
                )}
              {key === smartMapList.findIndex((val) => val[0]?.limited === true && val[0]?.edition === null) &&
                val.length > 0 && (
                  <AvaliablityLabel
                    style={{
                      marginTop: 8,
                    }}
                  >
                    LIMITED EDITION
                  </AvaliablityLabel>
                )}
              <div
                style={{
                  marginTop: 4,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <SmartColorSelector
                  key={key}
                  selectedColor={selectedColor}
                  setSelectedColor={handleColourChange}
                  colors={val}
                />
              </div>
            </div>
          ))}
          <br />
        </>
      )}
      <div ref={inViewRef}>
        {selectedVariant.oosData?.avaliable === false ? (
          <>
            <SignupSection>
              <SignupContent>
                {sent ? (
                  <SignupSubHeading>
                    <br /> Thanks! You'll be the first to know when it launches.{' '}
                  </SignupSubHeading>
                ) : (
                  <>
                    <SignupHeading> Notify me when this item is back in stock. </SignupHeading>
                    {
                      isPhone && (
                        <SignupSubHeading>
                          By submitting this form and signing up for texts, you consent to receive marketing text messages (e.g. promos, cart reminders) from July at the number provided, including messages sent by autodialer. Consent is not a condition of purchase. Msg & data rates may apply. Msg frequency varies. Unsubscribe at any time by replying STOP or clicking the unsubscribe link (where available). <Link to="/privacy-policy/">Privacy Policy</Link> & <Link to="/terms-conditions/">Terms</Link>
                        </SignupSubHeading>
                      )
                    }
                    <EmailButtonContainer>
                      <TextInputContainer>
                        <TextInput
                          value={email}
                          onKeyDown={add}
                          onChange={handleChange}
                          placeholder={placeholderSignUp}
                        />
                      </TextInputContainer>
                      <TransparentButtonSign onClick={handleEnter} color="terracota">
                        SUBSCRIBE
                      </TransparentButtonSign>
                    </EmailButtonContainer>
                    <div
                      style={{
                        fontWeight: 600,
                        color: 'rgb(var(--terracota))',
                      }}
                    >
                      {(!emailValid && !phoneValid) && 'Email or Phone not vaild.'}
                    </div>
                  </>
                )}
              </SignupContent>
            </SignupSection>
          </>
        ) : (
          <>
            <AddToCartPersContainer>
              {showPersonalisation === true ? (
                <div
                  style={
                    showPersonalisation && petPersonalised === true
                      ? { display: 'block', flex: '30%'}
                      : { display: 'none' }
                  }
                >
                  <NewPersonaliseButton
                    background={variants[selectedColor]?.ColourName === 'Blush Pink' ? variants[selectedColor]?.Colour : undefined}
                    personalisationPrice={personalisationCost}
                    freePersonalisation={freePersonalisation}
                    freeSuitcasePersonalisation={freeSuitcasePersonalisation}
                    isLuggage={selectedVariant.product_categories?.includes("Luggage")}
                    isBag={selectedVariant.product_categories?.includes("Bags", "Soft Good (Excluding Weekenders)", "Weekender")}
                    noSet={noSet}
                    productPrice={price}
                    setPersonaliseOpen={setPersonaliseOpen}
                    labelData={labelData}
                    personalised={personalised}
                    trackingItem={trackingItem}
                    persDisabled={false}
                  />
                </div>
              ) : (
                hasPersonlisation && !disablePersonalisation && (isBag && disableEmbroidery ? false : true) && (
                  <></>
                )
              )}
              <AddToCartContainer showPersonalisation={showPersonalisation} className={`product-${reviewId}-price`}>
                {
                  selectedVariant.oosData?.on_site === false ||
                    selectedVariant.oosData?.avaliable === false ? (
                    <OutOfStockButton onClick={() => { }}>
                      <span>OUT OF STOCK</span>
                    </OutOfStockButton>
                  ) : (
                    <>
                      <AddToCartButton onClick={handleAddToCart} background={variants[selectedColor]?.ColourName === 'Blush Pink' ? variants[selectedColor]?.Colour : undefined}>
                        <div style={{ display: 'flex', alignItems: 'start', fontWeight: 600 }}>
                          <AddToCartIcon src={bag} alt={bag} style={{ width: 16, marginTop: -1, marginRight: 8 }} />
                          <span>ADD TO CART</span>
                        </div>
                        <AddToCartPrice>
                          {discount?.amount > 0 && (!hideBundlePricing || discountPromoOn) ? (
                            <>
                              <span
                                style={{
                                  textDecoration: 'line-through',
                                  marginRight: 4,
                                  color: '#ffffffb3',
                                  fontSize: 12,
                                }}
                              >
                                {currencySymbol}{price}{" "}{currency}
                              </span>
                              {currencySymbol}{price - discount.amount}{" "}{currency}
                            </>
                          ) : (
                            <>
                              {currencySymbol}{price}{" "}{currency}
                            </>
                          )}
                        </AddToCartPrice>
                      </AddToCartButton>
                    </>
                  )
                }
              </AddToCartContainer>
            </AddToCartPersContainer>
            {
              selectedVariant.oosData?.avaliable === true && storeLocale !== 'en-NZ' && (
                <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: 14, letterSpacing: '-0.5px', justifyContent: 'flex-end', color: 'grey' }}>
                  {!hideBundlePricing || discountPromoOn ? (
                    <>
                      {storeLocale === 'en' || storeLocale === 'en-GB' ? (
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                          {"or 4 installments of "}{(price - discount.amount) / 4}
                          {" with "}
                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/black/klarna.svg" alt="Klarna Logo" width={40} style={{ margin: '0 5px' }} />
                          </span>
                        </span>
                      ) : (
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                          {"or 4 installments of "}{(price - discount.amount) / 4}
                          {" with "}
                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <AfterpayLogo
                              image={getImage(afterpayLogo?.childImageSharp?.gatsbyImageData)}
                              alt={"Afterpay Logo"}
                            />
                          </span>
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {(storeLocale === 'en' || storeLocale === 'en-GB') ? (
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                          {"or 4 installments of "}{price / 4}
                          {" with "}
                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <img src="https://x.klarnacdn.net/payment-method/assets/badges/generic/black/klarna.svg" alt="Klarna Logo" width={40} style={{ margin: '0 5px' }} />
                          </span>
                        </span>
                      ) : (
                        <span style={{ display: 'flex', justifyContent: 'center' }}>
                          {"or 4 installments of "}{price / 4}
                          {" with "}
                          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <AfterpayLogo
                              image={getImage(afterpayLogo?.childImageSharp?.gatsbyImageData)}
                              alt={"Afterpay Logo"}
                            />
                          </span>
                        </span>
                      )}
                    </>
                  )}
                </div>
              )
            }
            <br />
          </>
        )}
      </div>
      <AccordionBlock
        small={true}
        initial={1}
        data={{
          Title: 'Focus on the parts of travel you love.',
          Description: 'Our signature luggage will be your forever travel companion with a lifetime warranty.',
          AccordionItem: expandSections.map((val, index) => ({
            AccordionTitle: val.Title,
            AccordionCopy: (
              <>
                <AccordianBody dangerouslySetInnerHTML={{__html: val.Body}}/>
                {
                  index === 1 && (
                    <>
                      {
                        comparison && (
                          <Link to={comparison.link}>
                            <div style={{ fontWeight: 400, textDecoration: 'underline', fontSize: 12, textAlign: 'center', marginTop: 12 }}>COMPARE ALL <span style={{ textTransform: 'uppercase' }}>{comparison.Collection}</span></div>
                          </Link>
                        )
                      }
                      <div style={{ fontWeight: 400, fontSize: 14, marginTop: 16, textAlign: 'center', margin: '16px', marginBottom: 32 }}>
                        <Link to="/faqs/#shipping-and-delivery" styles={{ textDecoration: 'underline' }}>
                          {(((storeLocale === 'en-AU' || storeLocale === 'en' || storeLocale === 'en-NZ') && price > 100) || (storeLocale === 'en-GB' && price > 100)) ? `Free` : 'Fast'} Shipping
                        </Link>

                        {
                          storeLocale !== 'en' && storeLocale !== 'en-AU' ? (
                            <>
                              ・
                              <Link to="/faqs/#return-policy" styles={{ textDecoration: 'underline' }}>
                                Free Returns
                              </Link>
                            </>
                          ) : (
                            <>
                              ・
                              <Link to="/faqs/#return-policy" styles={{ textDecoration: 'underline' }}>
                                100 Day Returns
                              </Link>
                            </>
                          )
                        }
                        {
                          AirlineReq && (
                            <>
                              ・
                              <button onClick={togglePopup} >
                                <span style={{ textDecoration: 'underline' }}>
                                  Airline Compatibility
                                </span>
                              </button>
                              {isOpen && <Popup onClose={togglePopup} data={AirlineReq} productName={shortName ? shortName : name} />}
                            </>
                          )
                        }
                      </div>
                    </>
                  )
                }
              </>
            ),
          })),
          BackgroundColor: '#f6f3f0'
        }}
      />
      {/* {
        tolstoyId && (
          <div
            className="tolstoy-stories"
            data-publish-id="5sanlcm4hl6wx"
            data-product-id={tolstoyId}
          />
        )
      } */}
      {
        upsell && selectedVariant?.oosData?.avaliable === true && filteredUpsell?.length !== 0 && (
          <div style={{
            margin: '20px 0',
            // padding: '0 20px', paddingLeft: 0, marginLeft: 0
          }}>
            <CartAddToCartModules
              product={upsell.Product}
              heading={undefined}
              subheading={upsell.Subheading}
              returnAddToCart={returnAddToCart}
              discount={upsell.Discount}
              addToCartText={undefined}
              selectedVariantColour={selectedVariant.ColourName}
              storeLocale={storeLocale}
              hideBundlePricing={hideBundlePricing}
            />
          </div>
        )
      }
    </div>
    </SelectorContainer >
  )
}

export default SelctorSection
